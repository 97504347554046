<template>
  <div class="teachers-wishlist_form">
    <vs-textarea label="My wishes" v-model="wishlist" />

    <div class="save-popup-button">
      <vs-button icon="save" @click="saveWishlist">Save</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeacherWishlistForm',
  props: ['teacher'],
  data() {
    console.log(this.teacher)
    return { wishlist: this.teacher.wishlist || null }
  },
  methods: {
    saveWishlist: function() {
      const { wishlist } = this
      this.$store
        .dispatch('teachers/saveTeachersWhishlistAction', {
          wishlist,
        })
        .then(() => {
          this.$emit('closePopup', this.closePopup)
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Error',
            text: 'Error in save whishlist',
            color: 'danger',
            position: 'top-right',
          })
        })
    },
  },
}
</script>

<style lang="scss">
.teachers-wishlist_form {
  .vs-textarea {
    height: 200px;
  }
}
</style>
