<template>
  <div class="books__list">
    <vs-table
      v-model="selected"
      search
      :data="booksListWithAmounts"
      @dblSelection="doubleClickHandler"
    >
      <template slot="header">
        <h3>Books ({{ books.length }})</h3>
      </template>
      <template slot="thead">
        <vs-th sort-key="name">Name</vs-th>
        <vs-th class="books__list-total-amount-cell">
          Total amount
        </vs-th>
        <vs-th v-for="bookType in bookTypes" :key="bookType.id">
          <vs-chip class="book-type">
            {{ bookType.name }}
          </vs-chip>
        </vs-th>
        <vs-th>
          <vs-chip class="book-type">
            Empty book type
          </vs-chip>
        </vs-th>
        <vs-th></vs-th>
        <vs-th sort-key="name">Tests count</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="tr.id" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].name">
            <vs-icon icon="import_contacts" />
            <span
              :style="{ backgroundColor: data[indextr].color }"
              class="group-color"
            ></span>
            {{ data[indextr].name }}
          </vs-td>
          <vs-td class="books__list-total-amount-cell">
            {{ tr.total_amount }}
          </vs-td>
          <vs-td v-for="(a, index) in tr.amounts" :key="index">
            {{ a.total_amount || '-' }}
          </vs-td>
          <vs-td :data="data[indextr].id">
            <vs-button
              v-if="$can('manage', 'books')"
              :data-id="data[indextr].id"
              radius
              color="dark"
              icon="edit"
              type="flat"
              @click="editBookHandle(data[indextr].id)"
            ></vs-button>
            <vs-button
              :data-id="data[indextr].id"
              radius
              color="dark"
              icon="visibility"
              type="flat"
              @click="viewBookHandler(data[indextr].id)"
            ></vs-button>
            <vs-button
              v-if="$can('manage', 'books')"
              :data-id="data[indextr].id"
              radius
              color="dark"
              icon="delete"
              type="flat"
              @click="deleteBook(data[indextr].id)"
            ></vs-button>
          </vs-td>
          <vs-td :data="data[indextr].schoolyear">{{
            data[indextr].tests_count || '-'
          }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      selected: [],
      selectedId: null,
    }
  },
  computed: {
    ...mapState({
      books: state => state.books.books,
      bookTypes: state => state.books.bookTypes,
    }),
    booksListWithAmounts: function() {
      const types = this.bookTypes.reduce((prev, cur) => {
        prev[cur.id] = cur
        return prev
      }, {})
      // добавляем данные для суммы без типа книги
      types['empty'] = {}
      // соединяем типы и суммы для каждой книги, считаем общую сумму
      return this.books.map(b => {
        // берем объект с суммой
        const bookAmounts = b.amounts || {}
        // объединяем суммы и типы
        let amountsObject = Object.assign({ ...types }, bookAmounts)
        // считаем общую сумму учебников
        let sum = Object.values(amountsObject).reduce((prev, cur) => {
          if (cur.total_amount) prev += cur.total_amount
          return prev
        }, 0)
        // присваиваем
        b.amounts = amountsObject
        b.total_amount = sum
        return b
      })
    },
  },
  methods: {
    deleteBook: function(id) {
      this.selectedId = id
      this.openAlert()
    },
    editBookHandle: function(id) {
      this.$emit('editBook', id)
    },
    doubleClickHandler: function(tr) {
      this.$router.push({ name: 'Book', params: { book_id: tr.id } })
    },
    viewBookHandler: function(id) {
      this.$router.push({ name: 'Book', params: { book_id: id } })
    },
    openAlert: function() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Delete book',
        text: 'Are you sure you want to delete the book?',
        accept: this.acceptAlert,
      })
    },
    acceptAlert: function() {
      this.$emit('deleteBook', this.selectedId)
      this.selectedId = null
    },
  },
}
</script>

<style></style>
