<template>
  <div class="teachers__form">
    <vs-row>
      <vs-col>
        <div class="teachers__form-header">
          <h3>General</h3>
          <vs-switch id="active" v-model="is_active">
            <span slot="on">Active</span>
            <span slot="off">Activate</span>
          </vs-switch>
          <div class="teachers__form__weight-field">
            <label for="">Teacher weight:</label>
            <vs-input size="middle" v-model="weight"></vs-input>
          </div>
          <vs-avatar
            title="Change"
            :src="avatar"
            size="large"
            @click="avatarClickHandler"
          />
        </div>

        <vs-input label="NickName" size="large" v-model="nickname"></vs-input>
        <vs-input label="Last name" size="large" v-model="last_name"></vs-input>
        <vs-input
          label="Frist name"
          size="large"
          v-model="first_name"
        ></vs-input>
        <vs-input
          label="Middle name"
          size="large"
          v-model="middle_name"
        ></vs-input>
        <vs-input size="large" label="Phones" v-model="phone"></vs-input>
        <div class="teachers__form__birthday-field">
          <v-date-picker v-model="birthdate">
            <template v-slot="{ inputValue, inputEvents }">
              <div>
                <vs-input
                  class="calendar-input"
                  :value="`${inputValue}`"
                  v-on="inputEvents"
                  size="large"
                  label="Birthday"
                />
              </div>
            </template>
          </v-date-picker>
        </div>
        <vs-textarea label="Description" />
      </vs-col>
      <vs-col>
        <h3>Salary and rate</h3>
        <vs-row class="teachers__form__salary">
          <vs-col>
            <vs-input
              size="large"
              label="Fixed Salary"
              v-model="fixed_salary"
            ></vs-input>
          </vs-col>
          <vs-col>
            <vs-input size="large" label="Rate" v-model="rate"></vs-input>
          </vs-col>
        </vs-row>
        <hr />
        <h3>Password settings</h3>
        <vs-input
          type="password"
          :success="teacher.password_exist"
          success-text="password exist"
          size="large"
          label="Password"
          v-model="password"
        ></vs-input>
        <hr />
        <h3>Roles</h3>
        <vs-checkbox
          v-if="$can('manage', 'roles')"
          v-model="roles"
          vs-value="admin"
          >Admin</vs-checkbox
        >
        <vs-checkbox
          v-if="$can('manage', 'roles')"
          v-model="roles"
          vs-value="operator"
          >Operator</vs-checkbox
        >
      </vs-col>
    </vs-row>

    <vs-upload :show-upload-button="false" @change="changeFile" />

    <div class="save-popup-button">
      <vs-button icon="save" @click="saveTeacher">Save</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeacherForm',
  props: ['teacher'],
  data() {
    let data = {
      nickname: '',
      first_name: '',
      last_name: '',
      middle_name: '',
      fixed_salary: 0,
      birthdate: '',
      rate: 0,
      avatar: '',
      phone: '',
      weight: 0,
      password: '',
      roles: [],
      is_acitve: true,
    }
    if (this.teacher.id) {
      data = { ...this.teacher }
      data.password = ''
      data.roles = data.options ? data.options.roles || [] : []
    }

    return { ...data }
  },
  methods: {
    saveTeacher: function() {
      // TODO: avatar to base64
      let {
        nickname,
        first_name,
        last_name,
        middle_name,
        fixed_salary,
        birthdate,
        rate,
        avatar,
        phone,
        roles,
        weight,
        is_active,
      } = this

      birthdate = birthdate ? birthdate.getTime() / 1000 : null

      const data = {
        nickname,
        first_name,
        last_name,
        middle_name,
        fixed_salary,
        birthdate,
        rate,
        avatar,
        phone,
        roles,
        weight,
        is_active,
      }
      if (this.password) {
        data.password = this.password
      }
      if (this.id) data.id = this.id
      this.$store
        .dispatch('teachers/saveTeachersAction', {
          teacher: data,
        })
        .then(() => {
          this.$emit('closePopup', this.closePopup)
          const user = JSON.parse(localStorage.getItem('user'))
          if (data.id == user.id) {
            localStorage.setItem(
              'user',
              JSON.stringify({
                id: data.id,
                nickname: data.nickname,
                options: this.teacher.options,
                weight: data.weight,
              })
            )
          }
        })
    },
    changeFile: function(event, files) {
      this.toBase64(files[0]).then(f => {
        this.avatar = f
      })
    },
    toBase64: function(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    avatarClickHandler: function() {
      const uploadEl = document.querySelector('.con-input-upload input')
      if (uploadEl) uploadEl.click()
    },
  },
}
</script>

<style lang="scss">
.teachers__form {
  padding: 0 5px;
  .vs-input {
    width: 100%;
    margin-top: 0.8rem;
  }
  & > .vs-row {
    flex-flow: row nowrap;
    & > .vs-col {
      flex: 1;
      padding: 1rem;
      margin: 0 1rem;
      box-shadow: 0 0 10px 1px #ddd;
      border-radius: 0.5rem;
    }
  }
  &-header {
    & > * {
      margin-right: 1rem;
    }
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    .vs-input {
      width: 100px;
      margin-top: 0;
    }
  }
  &__weight-field {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex: 1;
  }
  &__birthday-field {
    display: flex;
    flex-flow: column nowrap;
  }
  &__salary {
    gap: 5px;
    flex-flow: row nowrap;
  }
  .con-upload {
    display: none;
  }
  hr {
    margin: 25px 0;
    color: #fff;
    border-color: #fff;
    background-color: #fff;
    border-style: solid;
  }
  .roles {
    & > div {
      align-items: flex-start;
      justify-content: flex-start;
      padding: 10px 0;
    }
  }
}
</style>
