<template>
  <div class="books">
    <div class="books__add-button add-button" v-if="$can('manage', 'books')">
      <vs-button icon="add" type="flat" @click="popupActive = true"
        >Add Book</vs-button
      >
    </div>
    <BooksList @editBook="editBook($event)" @deleteBook="deleteBook($event)" />
    <vs-popup
      class="books__popup"
      classContent="books__popup-content"
      title="Book"
      :active.sync="popupActive"
      @close="closePopupHandle"
    >
      <BookForm
        v-if="popupActive && selectedBook"
        :book="selectedBook"
        @closePopup="closePopup"
      />
    </vs-popup>
  </div>
</template>

<script>
import BooksList from '@/components/books/list.vue'
import BookForm from '@/components/books/form.vue'
import { mapState } from 'vuex'

export default {
  title: 'SE-Books',
  data() {
    return {
      popupActive: false,
      selectedBook: {},
    }
  },
  computed: {
    ...mapState({
      books: state => state.books.books,
      singleBook: state => state.books.book,
    }),
  },
  components: { BooksList, BookForm },
  created: async function() {
    await this.$store.dispatch('books/getBookTypesAction')
    await this.$store.dispatch('books/getBooksAction')
  },
  methods: {
    editBook: function(id) {
      if (!id) {
        this.$vs.notify({
          title: 'Error',
          text: `Error open Book`,
          color: 'danger',
          position: 'top-right',
        })
        return
      }
      this.$store.dispatch('books/getSingleBookAction', { id }).then(data => {
        this.popupActive = true
        this.selectedBook = data
      })
    },
    deleteBook: function(id) {
      this.$store
        .dispatch('books/deleteBooksAction', id)
        .then(() => {
          this.selectedBook = {}
          this.popupActive = false
          this.$vs.notify({
            title: 'Removed',
            text: `Book removed`,
            color: 'success',
            position: 'top-right',
          })
        })
        .catch(r => {
          this.selectedBook = {}
          this.popupActive = false
          this.$vs.notify({
            title: 'Error',
            text: r.message,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    closePopup: function() {
      this.popupActive = false
      this.selectedBook = {}
    },
    closePopupHandle: function() {
      this.selectedBook = {}
    },
  },
}
</script>

<style lang="scss">
.books__list {
  clear: both;
  &-total-amount-cell {
    background-color: #fbfbfb;
    border: 1px solid #eee !important;
    font-weight: bold;
  }
  .vs-table--thead {
    position: sticky;
    top: 50px;
  }
}
</style>
