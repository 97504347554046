import getBooks from '@/api/books/getBooks'
import getStudentBooks from '@/api/books/getStudentBooks'
import getGroupBooks from '@/api/books/getGroupBooks'
import getSingleBook from '@/api/books/getSingleBook'
import setBooks from '@/api/books/setBooks'
import deleteBook from '@/api/books/deleteBook'
import getTests from '@/api/books/getTests'
import giveOutToStudent from '@/api/books/giveOutToStudent'
import cancelOut from '@/api/books/cancelOut'
import deleteOut from '@/api/books/deleteOut'
import setOut from '@/api/books/setOut'
import getGroupsByBook from '@/api/books/getGroupsByBook'
import getBookAmounts from '@/api/books/getBookAmounts'
import deleteBookIncome from '@/api/books/deleteBookIncome'
import setBookIncome from '@/api/books/setBookIncome'
import getBookTypes from '@/api/books/getBookTypes'
import setBookTypes from '@/api/books/setBookTypes'
import deleteBookType from '@/api/books/deleteBookType'

const state = {
  books: [],
  singleBook: {},
  tests: [],
  studentBooks: [],
  groupBooks: [],
  groupsBySingleBook: [],
  bookAmounts: [],
  bookTypes: [],
}

// const getters = {
//     group: (state) => {
//         return state.singleGroup
//     }
// }

const actions = {
  // -- BOOKS
  getBooksAction({ commit }, filters) {
    return new Promise((resolve, reject) => {
      getBooks
        .call(this, filters)
        .then(response => {
          if (response.status == 'OK') {
            commit('setBooksMutation', { books: response.data })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  getStudentBooksAction({ commit }, filters) {
    return new Promise((resolve, reject) => {
      getStudentBooks
        .call(this, filters)
        .then(response => {
          if (response.status == 'OK') {
            commit('setStudentBooksMutation', { books: response.data })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  getGroupBooksAction({ commit }, filters) {
    return new Promise((resolve, reject) => {
      getGroupBooks
        .call(this, filters)
        .then(response => {
          if (response.status == 'OK') {
            commit('setGroupBooksMutation', { books: response.data })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  getSingleBookAction({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      getSingleBook
        .call(this, id)
        .then(response => {
          if (response.status == 'OK') {
            commit('setSingleBookMutation', { book: response.data })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  saveBookAction({ commit }, { book }) {
    return new Promise((resolve, reject) => {
      setBooks
        .call(this, { books: [book] })
        .then(response => {
          if (response.status == 'OK') {
            commit('updateBooksMutation', { books: response.data })
            commit('setSingleBookMutation', { book: response.data[0] })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  deleteBooksAction({ commit }, id) {
    return new Promise((resolve, reject) => {
      deleteBook
        .call(this, id)
        .then(response => {
          if (response) {
            commit('deleteBooksMutation', { book: { id } })
            resolve()
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(e.body)
        })
    })
  },
  getTestsAction({ commit }, filters) {
    return new Promise((resolve, reject) => {
      getTests
        .call(this, filters)
        .then(response => {
          if (response.status == 'OK') {
            commit('setTestsMutation', { tests: response.data })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  giveOutBookToStudentAction({ commit }, { out_id, lesson_id }) {
    return new Promise((resolve, reject) => {
      giveOutToStudent
        .call(this, { out_id, lesson_id })
        .then(response => {
          if (response) {
            commit('giveOutBookToStudentMutation')
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(e.body)
        })
    })
  },
  setBookOutAction({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      setOut
        .call(this, { id, data })
        .then(response => {
          if (response.status == 200) {
            commit('giveOutBookToStudentMutation')
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(e.body)
        })
    })
  },
  cancelBookOutsAction({ commit }, { bookOuts }) {
    return new Promise((resolve, reject) => {
      cancelOut
        .call(this, { book_outs: bookOuts })
        .then(response => {
          if (response.status == 204) {
            commit('giveOutBookToStudentMutation')
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(e.body)
        })
    })
  },
  deleteBookOutsAction({ commit }, { bookOuts }) {
    return new Promise((resolve, reject) => {
      deleteOut
        .call(this, { book_outs: bookOuts })
        .then(response => {
          if (response.status == 204) {
            commit('giveOutBookToStudentMutation')
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(e.body)
        })
    })
  },
  getGroupsByBookAction({ commit }, { id, schoolyear_id }) {
    return new Promise((resolve, reject) => {
      getGroupsByBook
        .call(this, { id, schoolyear_id })
        .then(response => {
          if (response.status == 'OK') {
            commit('setGroupsByBookMutation', { groups: response.data })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  getBookAmountsAction({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      getBookAmounts
        .call(this, { id })
        .then(response => {
          if (response.status == 'OK') {
            commit('setBookAmountsMutation', { groups: response.data })
            resolve(response)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  setBookIncomeAction({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      setBookIncome
        .call(this, { id, data })
        .then(response => {
          if (response.status == 200) {
            commit('setBookIncomeMutation')
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(e.body)
        })
    })
  },
  deleteBookIncomeAction({ commit }, { incomeId }) {
    return new Promise((resolve, reject) => {
      deleteBookIncome
        .call(this, { incomeId })
        .then(response => {
          if (response.status == 204) {
            commit('deleteBookIncomeMutation')
            resolve(response)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(e.body)
        })
    })
  },
  clearStudentBooksAction({ commit }) {
    commit('clearStudentBooksMutation')
  },
  clearGroupBooksAction({ commit }) {
    commit('clearGroupBooksMutation')
  },
  // BOOK TYPES
  getBookTypesAction({ commit }, filters) {
    return new Promise((resolve, reject) => {
      getBookTypes
        .call(this, filters)
        .then(response => {
          if (response.status == 'OK') {
            commit('setBookTypesMutation', { bookTypes: response.data })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  saveBookTypeAction({ commit }, { bookType }) {
    return new Promise((resolve, reject) => {
      setBookTypes
        .call(this, { bookTypes: [bookType] })
        .then(response => {
          if (response.status == 'OK') {
            commit('updateBookTypesMutation', { bookTypes: response.data })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  deleteBookTypeAction({ commit }, id) {
    return new Promise((resolve, reject) => {
      deleteBookType
        .call(this, id)
        .then(response => {
          if (response) {
            commit('deleteBookTypeMutation', { book: { id } })
            resolve()
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(e.body)
        })
    })
  },
  // updateLessonsAction({ commit }, group_id) {
  //     return new Promise((resolve, reject) => {
  //         updateLessons.call(this, group_id).then(response => {
  //             if (response.status == 'OK') {
  //                 commit('updateGroupsMutation', { book: [response.data] })
  //                 commit('setSingleBookMutation', { group: response.data })
  //                 resolve(response.data)
  //             }
  //             else { reject(new Error(response.message)) }
  //         }).catch(e => { reject(new Error(e)) })
  //     })
  // },
  // // -- GROUP TYPES
  // getGroupTypesAction({ commit }) {
  //     return new Promise((resolve, reject) => {
  //         getGroupTypes.call(this).then(response => {
  //             if (response.status == 'OK') {
  //                 commit('setGroupTypesMutation', { types: response.data })
  //                 resolve()
  //             }
  //             else { reject(new Error(response.message)) }
  //         }).catch(e => { reject(new Error(e)) })
  //     })
  // },
  // setGroupTypesAction({ commit }, { groupType }) {
  //     return new Promise((resolve, reject) => {
  //         setGroupTypes.call(this, { groupTypes: [groupType] }).then(response => {
  //             if (response.status == 'OK') {
  //                 commit('updateGroupTypesMutation', { types: response.data })
  //                 resolve()
  //             }
  //             else { reject(new Error(response.message)) }
  //         }).catch(e => { reject(new Error(e)) })
  //     })
  // },
  // deleteGroupTypeAction({ commit }, { groupType }) {
  //     return new Promise((resolve, reject) => {
  //         deleteGroupTypes.call(this, groupType.id).then(response => {
  //             if (response.status == 'OK') {
  //                 commit('deleteGroupTypeNutation', { type: response.data })
  //                 resolve()
  //             }
  //             else { reject(new Error(response.message)) }
  //         }).catch(e => { reject(new Error(e)) })
  //     })
  // }
}

const mutations = {
  // -- GROUPS
  setBooksMutation(state, { books }) {
    state.books = books
  },
  setStudentBooksMutation(state, { books }) {
    state.studentBooks = books
  },
  setSingleBookMutation(state, { book }) {
    state.singleBook = book
  },
  setGroupBooksMutation(state, { books }) {
    state.groupBooks = books
  },
  updateBooksMutation(state, { books }) {
    books.forEach(book => {
      const index = state.books.findIndex(r => r.id == book.id)
      if (index !== -1 && typeof index !== 'undefined') {
        state.books.splice(index, 1, book)
      } else {
        state.books = [...state.books, book]
      }
    })
  },
  deleteBooksMutation(state, { book }) {
    const index = state.books.findIndex(r => r.id == book.id)
    if (typeof index !== 'undefined') {
      state.books.splice(index, 1)
    }
  },
  clearStudentBooksMutation(state) {
    state.studentBooks = []
  },
  clearGroupBooksMutation(state) {
    state.studentBooks = []
  },
  // -- TESTS
  setTestsMutation(state, { tests }) {
    state.tests = tests
  },

  // --outs
  giveOutBookToStudentMutation() {},

  // -- groups by book
  setGroupsByBookMutation(state, { groups }) {
    state.groupsBySingleBook = groups
  },
  // -- book amounts
  setBookAmountsMutation(state, { groups }) {
    state.bookAmounts = groups
  },
  // -- book income
  setBookIncomeMutation() {},
  deleteBookIncomeMutation() {},
  // BOOK TYPES
  setBookTypesMutation(state, { bookTypes }) {
    const types = bookTypes.sort((a, b) => a.id - b.id)
    state.bookTypes = [...types]
  },
  updateBookTypesMutation() {},
  deleteBookTypeMutation() {},
  // setGroupTypesMutation(state, { types }) {
  //     state.groupTypes = types
  // },
  // updateGroupTypesMutation(state, { types }) {
  //     types.forEach(type => {
  //         const index = state.groupTypes.findIndex(r => r.id == type.id)
  //         if (index !== -1 && typeof index !== 'undefined') {
  //             state.groupTypes.splice(index, 1, type)
  //         } else {
  //             state.groupTypes = [...state.groupTypes, type]
  //         }
  //     })
  // },
  // deleteGroupTypeNutation(state, { type }) {
  //     const index = state.groupTypes.findIndex(r => r.id == type.id)
  //     if (typeof index !== 'undefined') {
  //         state.groupTypes.splice(index, 1)
  //     }
  // },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
