<template>
  <div>
    <svg width="100%" height="50px">
      <path
        d="M0 0 C 0 0, 3 3, 5 5,
             C 5 5, 10 20, 25 10, 
             C 25 10, 30 30, 35 15, 
             C 35 15, 35 35, 65 25, 
             C 65 25, 80 45, 105 20, 
             C 105 20, 120 35, 135 15, 
             C 135 15, 150 25, 165 25, 
             C 165 25, 180 35, 195 5, 
             C 195 5, 210 25, 235 5, 
             C 235 5, 250 25, 265 15, 
             C 265 15, 280 45, 295 25, 
             C 295 25, 310 55, 335 20, 
             C 335 25, 350 45, 375 10, 
             C 375 10, 390 25, 405 5, 
             C 405 5, 410 5, 425 0
             "
        stroke="black"
        fill="transparent"
      />
      <circle cx="5" cy="5" r="5" class="red" />
      <circle cx="25" cy="10" r="5" class="blue" />
      <circle cx="35" cy="15" r="5" class="green" />
      <circle cx="65" cy="25" r="5" class="yellow" />
      <circle cx="105" cy="20" r="5" class="red" />
      <circle cx="135" cy="15" r="5" class="blue" />
      <circle cx="165" cy="25" r="5" class="green" />
      <circle cx="195" cy="5" r="5" class="yellow" />
      <circle cx="235" cy="5" r="5" class="red" />
      <circle cx="265" cy="15" r="5" class="blue" />
      <circle cx="295" cy="25" r="5" class="green" />
      <circle cx="335" cy="20" r="5" class="yellow" />
      <circle cx="375" cy="10" r="5" class="red" />
      <circle cx="405" cy="5" r="5" class="blue" />
      <svg:style>
        .garland {
          --red: #ff2c2c;
          --yellow: #ffeb00;
          --green: #00ff5d;
          --blue: #0095ff;
          --speed: 1.5s;
        }

        .red {
          fill: var(--red);
          box-shadow: -5px -5px 5px black;
          animation: var(--speed) alternate 0s infinite blinkRed;
        }

        .yellow {
          fill: var(--yellow);
          animation: var(--speed) alternate 0s infinite blinkYellow;
        }

        .blue {
          fill: var(--blue);
          animation: var(--speed) alternate 0s infinite blinkBlue;
        }

        .green {
          fill: var(--green);
          animation: var(--speed) alternate 0s infinite blinkGreen;
        }

        @keyframes blinkRed {
          0% {
            fill: var(--red);
          }
          25% {
            fill: var(--blue);
          }
          50% {
            fill: var(--green);
          }
          75% {
            fill: var(--yellow);
          }
        }

        @keyframes blinkYellow {
          0% {
            fill: var(--yellow);
          }
          25% {
            fill: var(--red);
          }
          50% {
            fill: var(--blue);
          }
          75% {
            fill: var(--green);
          }
        }

        @keyframes blinkBlue {
          0% {
            fill: var(--blue);
          }
          25% {
            fill: var(--green);
          }
          50% {
            fill: var(--yellow);
          }
          75% {
            fill: var(--red);
          }
        }

        @keyframes blinkGreen {
          0% {
            fill: var(--green);
          }
          25% {
            fill: var(--yellow);
          }
          50% {
            fill: var(--red);
          }
          75% {
            fill: var(--blue);
          }
        }
      </svg:style>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss"></style>
