import Vue from 'vue'
import getTeachers from '@/api/teachers/getTeachers'
import setTeachers from '@/api/teachers/setTeachers'
import getSingleTeacher from '@/api/teachers/getSingleTeachers'
import deleteTeachers from '@/api/teachers/deleteTeachers'
import getMissingCauses from '@/api/teachers/getMissingCauses'
import setMissingCause from '@/api/teachers/setMissingCause'
import deleteMissingCause from '@/api/teachers/deleteMissingCause'
import getTeacherMissing from '@/api/teachers/getTeacherMissing'
import setTeacherMissing from '@/api/teachers/setTeacherMissing'
import deleteMissing from '@/api/teachers/deleteMissing'
import downloadTimeSheet from '@/api/teachers/downloadTimeSheet'
import getNotes from '@/api/teachers/getNotes'
import setNote from '@/api/teachers/setNote'
import deleteNote from '@/api/teachers/deleteNote'
import setTeacherWishlist from '@/api/teachers/setTeacherWishlist'

const state = {
  teachers: [],
  singleTeacher: {},
  causes: [],
  missings: [],
  notes: [],
}

const actions = {
  getTeachersAction({ commit }, data) {
    return new Promise((resolve, reject) => {
      getTeachers
        .call(this, { status: data?.status })
        .then(response => {
          if (response.status == 'OK') {
            commit('setTeachersMutation', { teachers: response.data })
            resolve()
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  saveTeachersAction({ commit }, { teacher }) {
    return new Promise((resolve, reject) => {
      setTeachers
        .call(this, { teachers: [teacher] })
        .then(response => {
          if (response.status == 'OK') {
            commit('updateTeachersMutation', { teachers: response.data })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  getSingleTeacherAction({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      getSingleTeacher
        .call(this, id)
        .then(response => {
          if (response.status == 'OK') {
            commit('setSingleTeacherMutation', { teacher: response.data })
            resolve()
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  deleteTeachersAction({ commit }, id) {
    return new Promise((resolve, reject) => {
      deleteTeachers
        .call(this, id)
        .then(response => {
          if (response.status == 'OK') {
            commit('deleteTeachersMutation', { teacher: response.data })
            resolve()
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  getTeacherMissingsAction({ commit }, { schoolyear, teacherId }) {
    return new Promise((resolve, reject) => {
      getTeacherMissing
        .call(this, { schoolyear, teacherId })
        .then(response => {
          if (response.status == 'OK') {
            commit('setTeacherMissingsMutation', { missings: response.data })
            resolve()
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  saveTeachersMissingAction({ commit }, { missing }) {
    return new Promise((resolve, reject) => {
      setTeacherMissing
        .call(this, {
          teacher: state.singleTeacher,
          missings: [missing],
        })
        .then(response => {
          if (response.status == 'OK') {
            commit('updateTeacherMissingMutation', { missings: response.data })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  deleteMissingAction({ commit }, { teacher_id, id }) {
    return new Promise((resolve, reject) => {
      deleteMissing
        .call(this, { teacher_id, id })
        .then(response => {
          if (response.status == 'OK') {
            commit('deleteMissingMutation', { missing: response.data })
            resolve()
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  getMissingCausesAction({ commit }) {
    return new Promise((resolve, reject) => {
      getMissingCauses
        .call(this)
        .then(response => {
          if (response.status == 'OK') {
            commit('setMissingCausesMutation', { causes: response.data })
            resolve()
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  saveMissingCausesAction({ commit }, { cause }) {
    return new Promise((resolve, reject) => {
      setMissingCause
        .call(this, { causes: [cause] })
        .then(response => {
          if (response.status == 'OK') {
            commit('updateMissingCausesMutation', { causes: response.data })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  deleteMissingCauseAction({ commit }, id) {
    return new Promise((resolve, reject) => {
      deleteMissingCause
        .call(this, id)
        .then(response => {
          if (response.status == 'OK') {
            commit('deleteMissingCauseMutation', { cause: response.data })
            resolve()
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  downloadTimeSheetAction({ commit }, { month, year }) {
    return new Promise((resolve, reject) => {
      downloadTimeSheet
        .call(this, { month, year })
        .then(response => {
          if (response.status == 200) {
            commit('downloadMutation')
            resolve(response)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  getNotesAction({ commit }, data) {
    return new Promise((resolve, reject) => {
      getNotes
        .call(this, data)
        .then(response => {
          if (response.status == 'OK') {
            commit('setNotesMutation', { notes: response.data })
            resolve()
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  saveNoteAction({ commit }, data) {
    return new Promise((resolve, reject) => {
      setNote
        .call(this, [data])
        .then(response => {
          if (response.status == 'OK') {
            commit('updateNotesMutation')
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  deleteNoteAction({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      deleteNote
        .call(this, { id })
        .then(response => {
          if (response.status == 204) {
            commit('updateNotesMutation')
            resolve(response.status)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  saveTeachersWhishlistAction({ commit }, { wishlist }) {
    return new Promise((resolve, reject) => {
      setTeacherWishlist
        .call(this, {
          teacher: state.singleTeacher,
          wishlist,
        })
        .then(response => {
          if (response.status == 'OK') {
            commit('setSingleTeacherMutation', { teacher: response.data })
            resolve(response.data)
          } else {
            reject(new Error(response.message))
          }
        })
        .catch(e => {
          reject(new Error(e))
        })
    })
  },
  clearNotesAction({ commit }) {
    return new Promise(() => {
      commit('clearNotesMutation')
    })
  },
}

const mutations = {
  setTeachersMutation(state, { teachers }) {
    state.teachers = teachers
  },
  updateTeachersMutation(state, { teachers }) {
    teachers.forEach(teacher => {
      const index = state.teachers.findIndex(r => r.id == teacher.id)
      if (index !== -1 && typeof index !== 'undefined') {
        state.teachers.splice(index, 1, teacher)
      } else {
        state.teachers = [...state.teachers, teacher]
      }
      if (teacher.id == state.singleTeacher.id) {
        teacher.birthdate = teacher.birthdate
          ? new Date(teacher.birthdate * 1000)
          : null
        state.singleTeacher = teacher
      }
    })
  },
  setSingleTeacherMutation(state, { teacher }) {
    teacher.birthdate = teacher.birthdate
      ? new Date(teacher.birthdate * 1000)
      : null
    state.singleTeacher = teacher
    console.log(state.singleTeacher)
  },
  deleteTeachersMutation(state, { teacher }) {
    const index = state.teachers.findIndex(r => r.id == teacher.id)
    if (typeof index !== 'undefined') {
      state.teachers.splice(index, 1)
    }
  },
  setTeacherMissingsMutation(state, { missings }) {
    state.missings = updateMissingsData.call(this, missings)
  },
  clearTeacherMissingsMutation(state) {
    state.missings = []
  },
  updateTeacherMissingMutation(state, { missings }) {
    let state_missings = [...state.missings]
    missings = updateMissingsData.call(this, missings)
    missings.forEach(mising => {
      const index = state.missings.findIndex(r => r.id == mising.id)
      if (index !== -1 && typeof index !== 'undefined') {
        state_missings.splice(index, 1, mising)
      } else {
        state_missings = [...state.missings, mising]
      }
    })
    state.missings = state_missings
  },
  deleteMissingMutation(state, { missing }) {
    let state_missings = [...state.missings]
    const index = state.missings.findIndex(r => r.id == missing.id)
    if (typeof index !== 'undefined') {
      state_missings.splice(index, 1)
    }
    state.missings = state_missings
  },
  setMissingCausesMutation(state, { causes }) {
    state.causes = causes
  },
  updateMissingCausesMutation(state, { causes }) {
    causes.forEach(cause => {
      const index = state.causes.findIndex(r => r.id == cause.id)
      if (index !== -1 && typeof index !== 'undefined') {
        state.causes.splice(index, 1, cause)
      } else {
        state.causes = [...state.causes, cause]
      }
    })
  },
  deleteMissingCauseMutation(state, { cause }) {
    const index = state.causes.findIndex(r => r.id == cause.id)
    if (typeof index !== 'undefined') {
      state.causes.splice(index, 1)
    }
  },
  downloadMutation() {},
  setNotesMutation(state, { notes }) {
    state.notes = notes
  },
  updateNotesMutation(state) {
    return state
  },
  clearNotesMutation(state) {
    state.notes = []
  },
}

function updateMissingsData(missings) {
  return missings.map(m => {
    let start = Vue.moment(m.date_start * 1000)
    let end = Vue.moment(m.date_end * 1000)
    let day_count = end.diff(start, 'days') + 1
    return {
      ...m,
      date_start: start,
      date_end: end,
      date_start_string: start.format('DD.MM.YYYY'),
      date_end_string: end.format('DD.MM.YYYY'),
      day_count,
    }
  })
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
