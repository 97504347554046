import Vue from 'vue'

export default function saveTeachers({ teacher, wishlist }) {
  const proxy = process.env.VUE_APP_PROXY
  const api_uri = process.env.VUE_APP_API_URI

  let url = proxy + api_uri + '/teachers/' + teacher.id + '/wishlist'
  return Vue.http
    .put(
      url,
      { wishlist },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => response.json())
    .catch(() => {
      throw 'Ошибка сохранения'
    })
}
