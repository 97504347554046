<template>
  <div>
    <svg width="100%" height="100%">
      <filter id="inset-shadow">
        <!-- Shadow blur -->
        <feGaussianBlur stdDeviation="-10" result="offset-blur" />
      </filter>
      <svg:style>
        .flakes circle {
          animation: flake-sway 2s ease-in-out 0s infinite normal both,
            flake-drop 5s ease-out 0s infinite normal both;
          filter: url(#inset-shadow);
        }
        @keyframes flake-sway {
          0% {
            transform: translateX(-5px);
          }
          50% {
            transform: translateX(5px);
          }
          100% {
            transform: translateX(-5px);
          }
        }
        @keyframes flake-drop {
          0% {
            opacity: 1;
            cy: -10px;
          }
          60% {
            opacity: 1;
          }
          100% {
            opacity: 0;
            cy: calc(100% + 5px);
          }
        }
      </svg:style>
      <g class="flakes" />
      <svg:script>
        const rand = (from, to) => Math.round((Math.random() * (to - from) +
        from) * 20) / 20;
        document.querySelector('.flakes').replaceChildren(...[...new
        Array(40)].map(()=>
        Object.assign(document.createElementNS('http://www.w3.org/2000/svg',
        'circle'), { style: ` cx: ${rand(5, 95)}%; r: ${rand(2, 6)}; fill:
        rgba(230,230,230,${rand(0.4, 0.9)}); ; animation-delay: -${rand(0,
        10)}s; animation-duration: ${rand(2, 4)}s, ${rand(4, 12)}s; `}) ));
      </svg:script>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss"></style>
